/* eslint-disable consistent-return */
import {
  AuthState,
  httpClientCancelAllRequests,
} from '@core/utils';

import {
  authCoreService,
  getDashboardsRedirectUrl,
  getPartnerDetails,
  getPersonalizationRedirectUrl,
  getDemandUrl,
  getKittUrl,
} from '@/utils/auth';

import {
  navigateToHc,
  getBenchDirectRedirectUrl,
  redirectToAdmin,
  getUserDetails,
  useLogger,
} from '@/utils';

import { store } from '@/store';
import { track } from '@/utils/analytics';
import { thnAppsUrl, adminAccountTypes } from '@/config';
import { useAccountsService } from '@/modules/Accounts/services/AccountsService';

import { useAuthService } from '@/modules/Auth/services/AuthService';
import { useLaunchDarklyService } from '@/services/launchDarkly';

export async function validateAccess(to, from, next) {
  const { logInfo, logError } = useLogger(`routerGuard - ${to.path}`);

  logInfo('*** Route validateAccess ***');

  const {
    login,
    logout,
    loginAs,
    initAuth,
    handleAuthRedirect,
  } = authCoreService;

  const { fullPath, meta } = to;

  // Cancel all pending request on route changes
  if (to.path !== from.path) {
    httpClientCancelAllRequests();
    window.Appcues?.page();
  }

  // If route does not require auth, continue
  if (!to.meta.requiresAuth) {
    next();
    return;
  }

  // If route is redirect to help center, store return_to in local storage
  if (to.path === '/redirect/helpcenter') {
    localStorage.setItem('hc_return_to', to.query.return_to || 'https://help.thehotelsnetwork.com/');
  }

  const firstLoad = !AuthState.initialized;
  const loginAsUserId = to.params.loginAsUserId || to.query.loginAs;

  // If Authstate is not initialized, await auth initialization fron core
  if (firstLoad) {
    await initAuth();
    logInfo('AuthState initialized');
  }

  if (AuthState.isAuthenticated && to?.name !== undefined) {
    track(`AC-go-to-${to.name}`);
  }

  // If user is not authenticated, redirect to login or loginAs
  if (!AuthState.isAuthenticated) {
    if (to.query.code && to.query.state) {
      await handleAuthRedirect();
    } else {
      if (loginAsUserId) {
        AuthState.auth0.options
          .redirect_uri += `?loginAs=${loginAsUserId}`;
      }
      logInfo('Go to login');

      await login();
      return;
    }
  }

  // If route is logout, logout and return
  if (fullPath.includes('logout')) {
    await logout();
  }

  // Handle user details upon login
  let { user } = useAuthService();

  const redirectUrl = !fullPath.includes('signup')
    ? fullPath
    : '';

  const { powerLevels } = to.query;

  if (firstLoad) {
    const impersonatedUserId = AuthState.impersonatedUser?.loggedAsUserId;

    if (loginAsUserId) {
      logInfo('getUserDetails');

      user = await getUserDetails(loginAsUserId);

      await loginAs(
        loginAsUserId,
        powerLevels,
      );
      logInfo('LoginAs');
    } else if (impersonatedUserId) {
      logInfo('getUserDetails');
      user = await getUserDetails(impersonatedUserId);
    } else if (AuthState.isPartner) {
      logInfo('getPartnerDetails');
      user = await getPartnerDetails(AuthState.user.email);
    } else {
      logInfo('getUserDetails');
      user = await getUserDetails(AuthState.userId);
    }

    store.commit('auth/LOGIN_SUCCESS', { user });

    if (to.query.pid) {
      redirectToAdmin(to.query.pid);
      return;
    }
  }

  const launchDarklyService = useLaunchDarklyService();
  await launchDarklyService.initClient(user);

  const accountsService = useAccountsService();

  // Check if route needs an account and if one is selected
  if (meta.requiresAccountSelected && !accountsService.account.id) {
    logInfo('Route requires an account');

    try {
      await accountsService.selectDefaultAccount();
      logInfo(`Account '${accountsService.account?.name}' selected`);
    } catch (error) {
      logError('SelectDefaultAccount error', error);
    }

    if (!accountsService.isValidAccountSelected) {
      next({
        path: '/accounts',
        query: { redirect: redirectUrl },
        replace: true,
      });
      return;
    }
  }

  // Process redirects received in route
  const { redirectTo, redirectPath } = to.params;

  if (redirectTo) {
    switch (redirectTo.toLocaleLowerCase()) {
      case 'dashboards':
        window.location.href = getDashboardsRedirectUrl();
        return;
      case 'benchdirect':
        window.location.href = getBenchDirectRedirectUrl();
        return;
      case 'personalization':
        window.location.href = getPersonalizationRedirectUrl();
        return;
      case 'conversion': {
        const { pid } = to.query;
        const indexOfQuery = fullPath.indexOf('?');
        if (indexOfQuery === -1) {
          redirectToAdmin(pid, redirectPath);
          return;
        }
        redirectToAdmin(pid, `${redirectPath}${fullPath.substring(indexOfQuery)}`);
        return;
      }
      case 'demand':
        window.location.href = getDemandUrl();
        return;
      case 'kitt':
        window.location.href = getKittUrl();
        return;
      case 'latte':
        window.location.href = thnAppsUrl.latte;
        return;
      default:
        next({
          path: '/products',
          replace: true,
        });
        return;
    }
  }

  // Redirect to latte if is internal user
  if (user.type === 'internal') {
    if (fullPath.includes('signup')) {
      next();
      return;
    }

    window.location.href = thnAppsUrl.latte;
    return;
  }

  if (
    // Check if route needs active properties for the user
    (meta.requiresActiveProperties
      && accountsService.account?.user?.active_properties_count <= 0)
    // Check if route is reserved for Team Member profiles only
    || (meta.forTeamMembersOnly && !user?.type.includes('team_member'))
    // Check if route is reserved for Admins or Account Owners only
    || (meta.requiresOwnerOrAdmin
      && !adminAccountTypes.includes(accountsService.account?.team_member?.role))
  ) {
    next({
      path: '/preferences',
      replace: true,
    });
    return;
  }
  if (loginAsUserId || to.query.code) {
    next({
      path: '/products',
      replace: true,
    });
    return;
  }

  logInfo(`*** User redirected to ${to.path} ***`);

  // If user came from a help center redirect, open help center tab
  const helpCenterRedirect = localStorage.getItem('hc_return_to');

  if (helpCenterRedirect) {
    localStorage.removeItem('hc_return_to');
    await (navigateToHc(helpCenterRedirect.replace(/^.*\/hc\/[^/]+\//, ''), false));
    return;
  }

  next();
}
